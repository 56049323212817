.search-container {
    margin-bottom: 50px;


}

.search-flex {
    display: flex;
    justify-content: center;
    gap: 15px;
    align-items: center;

}

.search-box {
    background-color: rgba(255, 255, 255, 0);

    border-radius: 4px;
    color: #eee;
    width: 300px;
    border: 2px solid #ddd;
    padding: 10px;

}

.search-icon {
    font-size: 20px;
    font-weight: 600;
}

.btn-search {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #eee;


}

.app-title {
    margin-bottom: 30px;
    text-align: center;
    color: #eee;
}

.error-mess {
    color: #eee;
    font-size: 12px;
    text-align: center;
    margin-top: 10px;

}